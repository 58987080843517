@use 'styles' as theme;

.wrapper {
  overflow: hidden;
  position: relative;
  margin-right: theme.size(0.5);

  @include theme.mediaTabletLandscapeUp {
    padding-left: theme.size(1.5);
  }
}

.container {
  @include theme.hideScrollbar;

  padding-left: theme.size(0.5);
  overflow: scroll;
}

.listWrapper {
  flex: 1;
}

.wrap {
  flex-wrap: nowrap;
}

.link {
  @include theme.transition(color, $focusOutline: true);
  @include theme.outlineOnFocus;

  border-radius: theme.$borderRadiusM;
  display: flex;
  color: theme.$colorBlack;

  &:hover {
    color: theme.$colorBlue;
  }
}

.active {
  color: theme.$colorBlue;

  &:hover {
    color: theme.$colorBlue900;
  }
}

.listContainer {
  padding: theme.size(1.5 0);
}

.shadowStart {
  position: absolute;
  top: 0;
  bottom: 0;
  width: theme.size(5);
  left: 0;
  background-image: linear-gradient(
    90deg,
    rgba(theme.$colorWhite, 1) 0%,
    rgba(theme.$colorWhite, 1) 25%,
    rgba(theme.$colorWhite, 0) 100%
  );
}

.shadowEnd {
  position: sticky;
  z-index: 1;
  flex: 0 0 theme.size(5);
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(theme.$colorWhite, 0) 0%,
    rgba(theme.$colorWhite, 1) 75%,
    rgba(theme.$colorWhite, 1) 100%
  );
}

.fullWidth {
  width: 100%;
}

.text {
  white-space: nowrap;
  padding: theme.size(0.5 2);
}
