@use 'styles' as theme;

.root {
  padding: theme.size(9) 0 theme.size(3);

  @include theme.mediaDesktopUp {
    padding: theme.size(9) 0 0;
  }
}

// Remove browser x icon, since we use a custom one
.textField input[type="search"]::-webkit-search-cancel-button {
  appearance: none;
}
