@use 'styles' as theme;

.featuredArticlesSection {
  padding: theme.size(4 0 5);

  @include theme.mediaTabletLandscapeUp {
    padding: theme.size(8 0);
  }
}

.shownArticlesSection {
  padding: theme.size(3 0);

  @include theme.mediaTabletLandscapeUp {
    padding: theme.size(4 0 8);
  }
}

.loadMoreSection {
  padding: theme.size(4 0 6);

  @include theme.mediaTabletLandscapeUp {
    padding: theme.size(5 0 8);
  }
}
