@use 'styles' as theme;

.root {
  outline: none;
}

.wrapper {
  @include theme.outlineOnFocus($selector: '.root:focus-visible &');

  border-radius: theme.$borderRadiusXS;
}

.authorImageWrapper {
  @include theme.mediaTabletPortraitDown {
    @include theme.circle(theme.size(4));
  }
}

.authorBlock {
  margin-top: auto;
}

.content {
  height: 100%;
}
