@use 'styles' as theme;

.root {
  outline: none;
  display: flex;
  height: 100%;
}

.wrapper {
  @include theme.outlineOnFocus($selector: '.root:focus-visible &');

  border-radius: theme.$borderRadiusXS;
  flex: 1;
}

.image {
  aspect-ratio: 16 / 9;
  object-fit: cover;
}
