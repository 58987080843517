@use 'styles' as theme;
@import 'styles/variables.module';

.root {
  height: theme.size(7);
}

.borderBottom {
  border-bottom: 1px solid theme.$colorGrey200;
}

.wrapper {
  position: fixed;
  left: 0;
  right: 0;
  background-color: theme.$colorWhite;
  z-index: 1;
}

.button {
  @include theme.unstyledButton;
  @include theme.outlineOnFocus;
  @include theme.transition(color, $focusOutline: true);

  border-radius: theme.$borderRadiusM;
  display: flex;
  color: theme.$colorBlack;
  margin-left: auto;

  &:hover {
    color: theme.$colorBlue;
  }
}

.closeButton {
  position: absolute;
  top: theme.size(3);
  right: 0;

  @include theme.mediaDesktopUp {
    top: theme.size(2.25);
  }
}

.searchBlockWrapper {
  position: relative;

  @include theme.mediaDesktopUp {
    margin-bottom: theme.size(9);
  }
}

.progressWrapper {
  position: sticky;
  top: $headerHeight;
  left: 0;
  right: 0;
  background-color: theme.$colorBlue50;
  height: theme.size(0.5);
  z-index: 1;
}

.progress {
  background-color: theme.$colorBlue;
  transform-origin: left;
  height: 100%;
}

.searchBlock {
  overflow: hidden;
}
